export default function ({
  path = '',
  title = '',
  description = '',
  og_title = '',
  og_description = '',
  og_image = '',
  twitter_description = '',
  twitter_title = '',
  twitter_image = '',
  isNoIndex = false,
}) {
  const index = {}

  if (title && !og_title) og_title = title
  if (title && !twitter_title) twitter_title = title
  if (description && !og_description) og_description = description
  if (description && !twitter_description) twitter_description = description

  if (og_image && !twitter_image) twitter_image = og_image
  if (twitter_image && !og_image) og_image = twitter_image

  const meta = [
    {
      hid: 'description',
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      hid: 'og:title',
      content: og_title,
    },
    {
      property: 'og:site_name',
      hid: 'og:site_name',
      content: title,
    },
    {
      property: 'og:description',
      hid: 'og:description',
      content: og_description,
    },
    {
      property: 'og:image',
      hid: 'og:image',
      content: og_image,
    },
    {
      name: 'twitter:card',
      hid: 'twitter:card',
      content: twitter_description,
    },
    {
      name: 'twitter:title',
      hid: 'twitter:title',
      content: twitter_title,
    },
    {
      name: 'twitter:image',
      hid: 'twitter:image',
      content: twitter_image,
    },
    index,
  ]

  if (isNoIndex) meta.push({ name: 'robots', content: 'noindex' })

  return {
    title,
    meta,
  }
}
